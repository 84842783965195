import Axios, { AxiosInstance, CancelTokenSource } from 'axios';
import { API_TIMEOUT, API_URL } from 'src/config';
import {
  requestAuthHandler,
  requestPaginationHandler,
  requestLoadingHandler,
  responseLoadingHandler,
  errorLoadingHandler,
  successHandler,
  errorHandler,
} from './axios-interceptors';

declare module 'axios' {
  interface AxiosRequestConfig {
    apiRequestId?: string;
    skipLoadingHandler?: boolean;
  }
}

// Utility to Add Cancel Token to Requests
export const getCancelTokenSource = (): CancelTokenSource => {
  return Axios.CancelToken.source();
};

// Utility to isCancel Token to Requests
export const axiosIsCancel = (error) => {
  return Axios.isCancel(error);
};

// Create Axios Instance
export const axiosInstance: AxiosInstance = Axios.create({
  baseURL: API_URL,
  timeout: API_TIMEOUT,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
});

// Register Axios Interceptors
export const registerAxiosInterceptors = (store: any) => {
  // Request Interceptor
  axiosInstance.interceptors.request.use((request) => {
    request = requestLoadingHandler(store)(request);
    request = requestAuthHandler(store)(request);
    return requestPaginationHandler(store)(request);
  });

  // Response Interceptor
  axiosInstance.interceptors.response.use(
    (response) => {
      response = responseLoadingHandler(store)(response);
      return successHandler(store)(response);
    },
    (error) => {
      error = errorLoadingHandler(store)(error);
      return errorHandler(store)(error);
    }
  );
};
