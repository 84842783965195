import { all, call, put, takeEvery } from 'redux-saga/effects';
import { API_ENDPOINTS } from 'src/config/api-endpoints';
import { convertToFormData } from 'src/utils/converter';
import { axiosInstance } from 'src/utils/axios/axios-instance';
import { generateSingleKey, removeCacheConfig } from 'src/utils/axios/axios-fecth';

import * as actions from './actions';
import { getWorkstationId, getStoreId } from '../auth/storage';

// Handle request saga
function* getSettingList(action: ReturnType<typeof actions.getSettingList.request>): Generator {
  try {
    const { code } = action.payload;
    let key_axios = generateSingleKey(API_ENDPOINTS.SETTING.List, {
      code: code,
    });

    let responseData = null;
    let cacheObject = window.localStorage.getItem(key_axios);
    if (cacheObject) {
      responseData = JSON.parse(cacheObject);
    } else {
      const response = yield call(axiosInstance.get, `${API_ENDPOINTS.SETTING.List}`, {
        params: action.payload,
      });

      responseData = (response as any).data;
      if (responseData.success) {
        window.localStorage.setItem(key_axios, JSON.stringify(responseData));
      }
    }

    yield put(actions.getSettingList.success(responseData));

    if (action.payload.code) {
      yield put(actions.clearGroupData(action.payload.code));
    }
    if (responseData.success && responseData.data) yield put(actions.setData(responseData.data));
  } catch (err) {
    yield put(actions.getSettingList.failure(err));
  }
}

function* updateSettings(action: ReturnType<typeof actions.updateSettings.request>): Generator {
  try {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: (data) => convertToFormData(data),
    };

    const { code, settings } = action.payload;
    const url = API_ENDPOINTS.SETTING.Update.replace('{id}', code);
    const response = yield call(() => axiosInstance.post(url, settings, config));

    yield put(actions.updateSettings.success((response as any).data));
    yield put(actions.hasUpdatedData());
    if (code) {
      //remove cache when user updated
      yield removeCacheConfig('', code, API_ENDPOINTS.SETTING.List);

      if (code === 'others') {
        yield removeCacheConfig('', 'initial', '', '');
      }

      if (code === 'print' || code === 'profile' || code === 'payment') {
        yield removeCacheConfig(getStoreId(), 'initial_profile', '', getWorkstationId());
      }

      yield put(actions.clearGroupData(code));
    }
  } catch (err) {
    yield put(actions.updateSettings.failure(err));
  }
}

function* updateManySettings(action: ReturnType<typeof actions.updateManySettings.request>): Generator {
  try {
    const response = yield call(() => axiosInstance.post(API_ENDPOINTS.SETTING.UpdateMany, action.payload));

    yield put(actions.updateManySettings.success((response as any).data));
  } catch (err) {
    yield put(actions.updateManySettings.failure(err));
  }
}

// Main saga
export default function* settingSaga() {
  yield all([takeEvery(actions.getSettingList.request, getSettingList)]);
  yield all([takeEvery(actions.updateSettings.request, updateSettings)]);
  yield all([takeEvery(actions.updateManySettings.request, updateManySettings)]);
}
