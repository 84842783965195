import { all, call, put, takeEvery } from 'redux-saga/effects';
import { API_ENDPOINTS } from 'src/config/api-endpoints';
import { axiosInstance, axiosIsCancel, getCancelTokenSource } from '../../../../utils/axios/axios-instance';
import { generateSingleKey } from '../../../../utils/axios/axios-fecth';

import * as actions from './actions';
import { getAuthData, setAuthData } from '../auth/storage';
import { updateInitialSettings } from '../auth/actions';
import { setInitialSettings } from './storage';
import { CodeListResponse } from 'src/state/api-models/code';
import { SettingListResponse } from 'src/state/api-models/setting';

import * as actionsCode from '../code/actions';
import * as actionsSetting from '../setting/actions';

function* getInitialGeneralSettings(action: ReturnType<typeof actions.getInitialGeneralSettings.request>): Generator {
  try {
    let responseData = null;
    let key_axios = (yield generateSingleKey(API_ENDPOINTS.SETTING.Initial, action.payload)) as string;
    let cacheObject = localStorage.getItem(key_axios);
    if (cacheObject) {
      responseData = JSON.parse(cacheObject);
    } else {
      const cancelTokenSource = getCancelTokenSource();

      const response = yield call(axiosInstance.get, API_ENDPOINTS.SETTING.Initial, {
        params: action.payload,
        cancelToken: cancelTokenSource.token,
      });
      responseData = (response as any).data;
      if (responseData.success) {
        window.localStorage.setItem(key_axios, JSON.stringify(responseData));
      }
    }

    yield put(actions.getInitialGeneralSettings.success(responseData));

    const initialSettings = responseData.data;
    if (initialSettings) {
      yield put(actions.setInitialGeneralSettingData(initialSettings));

      // update setting of login store
      const loginData = getAuthData();
      loginData.initial_settings.generalSettings = initialSettings;

      yield put(updateInitialSettings(initialSettings));
      yield call(() => setInitialSettings(initialSettings));
      yield call(() => setAuthData(loginData));
    }
  } catch (err) {
    if (axiosIsCancel(err)) {
      console.log('Request canceled:', err.message);
    } else {
      // Dispatch failure action for other errors
      yield put(actions.getInitialGeneralSettings.failure(err));
    }
  }
}

function* getInitialStoreSettings(action: ReturnType<typeof actions.getInitialStoreSettings.request>): Generator {
  try {
    const { store_id } = action.payload;

    let key_axios = (yield generateSingleKey(API_ENDPOINTS.STORE_SETTING.Initial, action.payload)) as string;
    let responseData = null;

    let cacheObject = localStorage.getItem(key_axios);
    if (cacheObject) {
      responseData = JSON.parse(cacheObject);
    } else {
      const cancelTokenSource = getCancelTokenSource();

      const response = yield call(axiosInstance.get, API_ENDPOINTS.STORE_SETTING.Initial, {
        params: action.payload,
        cancelToken: cancelTokenSource.token,
      });

      responseData = (response as any).data;
      if (responseData.success) {
        window.localStorage.setItem(key_axios, JSON.stringify(responseData));
      }
    }

    yield put(actions.getInitialStoreSettings.success(responseData));

    const initialSettings = responseData.data;
    if (store_id && initialSettings) {
      yield put(actions.setInitialStoreSettingData({ storeId: store_id, data: initialSettings }));

      // update setting of login store
      const loginData = getAuthData();
      if (loginData.store_id === store_id) {
        loginData.initial_settings.storeSettings = initialSettings;

        yield put(updateInitialSettings(initialSettings));
        yield call(() => setInitialSettings(initialSettings));
        yield call(() => setAuthData(loginData));
      }
    }
  } catch (err) {
    if (axiosIsCancel(err)) {
      console.log('Request canceled:', err.message);
    } else {
      // Dispatch failure action for other errors
      yield put(actions.getInitialStoreSettings.failure(err));
    }
  }
}

function* getInitialProfileSettings(action: ReturnType<typeof actions.getInitialProfileSettings.request>): Generator {
  try {
    let responseData = null;

    const { store_id, workstation_id } = action.payload;
    let key_axios = (yield generateSingleKey(API_ENDPOINTS.STORE_SETTING.InitialProfile, action.payload)) as string;

    let cacheObject = localStorage.getItem(key_axios);
    if (cacheObject) {
      responseData = JSON.parse(cacheObject);
    } else {
      const response = yield call(axiosInstance.get, API_ENDPOINTS.STORE_SETTING.InitialProfile, {
        params: action.payload,
      });

      responseData = (response as any).data;
      if (responseData.success) {
        window.localStorage.setItem(key_axios, JSON.stringify(responseData));
      }
    }

    yield put(actions.getInitialProfileSettings.success(responseData));

    const initialSettings = responseData.data;
    if (store_id && initialSettings) {
      if (initialSettings.workstations) {
        const codePayload = { codeName: 'stores_workstations', keyCodeName: undefined };
        const keyCodeAxios = (yield generateSingleKey(API_ENDPOINTS.CODE.List, codePayload)) as string;

        let cacheObjectCode = yield window.localStorage.getItem(keyCodeAxios);
        if (!cacheObjectCode) {
          const storeResponse: CodeListResponse = {
            data: initialSettings.workstations,
            codeName: 'stores_workstations',
            success: true,
            message: 'code.getList_success',
          };

          yield window.localStorage.setItem(keyCodeAxios, JSON.stringify(storeResponse));

          yield put(
            actionsCode.getCodeList.success({
              responseData: storeResponse,
              codeName: 'stores_workstations',
              keyCodeName: codePayload.codeName,
            })
          );
        }
      }

      if (initialSettings.profile) {
        let keyAxiosProfile = generateSingleKey(API_ENDPOINTS.STORE_SETTING.List, {
          code: 'profile',
          store_id: store_id,
        });

        let cacheObjectProfile = yield window.localStorage.getItem(keyAxiosProfile as string);
        if (!cacheObjectProfile) {
          const profileResponse: SettingListResponse = {
            data: {
              profile: initialSettings.profile,
            },
            success: true,
            message: 'setting.get_success',
          };

          yield window.localStorage.setItem(keyAxiosProfile, JSON.stringify(profileResponse));
          yield put(actionsSetting.getSettingList.success(profileResponse));

          if (store_id) {
            yield put(actionsSetting.clearGroupData('profile'));
            yield put(
              actionsSetting.setData({
                storeId: store_id,
                data: {
                  profile: initialSettings.profile,
                },
              })
            );
          }
        }
      }

      if (initialSettings.initial) {
        let keyAxiosInitial = generateSingleKey(API_ENDPOINTS.STORE_SETTING.Initial, {
          store_id: store_id,
          workstation_id: workstation_id,
        });

        let cacheObjectInitial = yield window.localStorage.getItem(keyAxiosInitial as string);
        if (!cacheObjectInitial) {
          const initalResponse: SettingListResponse = {
            data: initialSettings.initial,
            success: true,
            message: 'setting.get_success',
          };

          yield window.localStorage.setItem(keyAxiosInitial, JSON.stringify(initalResponse));
          yield put(actions.getInitialStoreSettings.success(initalResponse));

          yield put(actions.setInitialStoreSettingData({ storeId: store_id, data: initialSettings.initial }));
        }
      }

      if (initialSettings.enter_pincode_screens) {
        let keyAxiosPinCode = generateSingleKey(API_ENDPOINTS.SETTING.Initial, {
          store_id: store_id,
          workstation_id: workstation_id,
        });

        let cacheObjectPinCode = yield window.localStorage.getItem(keyAxiosPinCode as string);

        if (!cacheObjectPinCode) {
          const pinCodeResponse: SettingListResponse = {
            data: {
              country_code: initialSettings.initial.country_code || 'US',
              enable_checkin_employee_daily: initialSettings.initial.enable_checkin_employee_daily || '0',
              enterPinCodeScreens: initialSettings.enter_pincode_screens,
            },
            success: true,
            message: 'setting.get_success',
          };

          yield window.localStorage.setItem(keyAxiosPinCode, JSON.stringify(pinCodeResponse));
          yield put(actions.getInitialGeneralSettings.success(pinCodeResponse));
        }
      }

      // update setting of login store
      const loginData = getAuthData();
      if (loginData.store_id === store_id) {
        const authInititalSetting = {
          storeSettings: initialSettings.initial,
          generalSettings: initialSettings.enter_pincode_screens,
        };

        loginData.initial_settings = authInititalSetting;
        yield put(updateInitialSettings(authInititalSetting));
        yield call(() => setInitialSettings(authInititalSetting));
        yield call(() => setAuthData(loginData));
      }
    }
  } catch (err) {
    yield put(actions.getInitialProfileSettings.failure(err));
  }
}

// Main saga
export default function* initialSettingSaga() {
  yield all([takeEvery(actions.getInitialGeneralSettings.request, getInitialGeneralSettings)]);
  yield all([takeEvery(actions.getInitialStoreSettings.request, getInitialStoreSettings)]);
  yield all([takeEvery(actions.getInitialProfileSettings.request, getInitialProfileSettings)]);
}
