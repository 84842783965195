import {
  InitialGeneralSettings,
  InitialGeneralSettingsResponse,
  InitialStoreSettings,
  InitialStoreSettingsResponse,
  InitialProfileSettingsResponse,
} from 'src/state/api-models/setting';
import { BaseState } from '../../models';
import { getInitialSettings } from './storage';

export type InitialGeneralSettingsState = BaseState<InitialGeneralSettingsResponse>;
export type InitialStoreSettingsState = BaseState<InitialStoreSettingsResponse>;
export type InitialStoreProfileState = BaseState<InitialProfileSettingsResponse>;

type StoreId = Exclude<string, 'general'>;

export type InitialSettingDataState = { general?: InitialGeneralSettings } & {
  [storeId: StoreId]: InitialStoreSettings | undefined;
};

export type SettingState = {
  data: InitialSettingDataState;
  generalSetting: InitialGeneralSettingsState;
  storeSetting: InitialStoreSettingsState;
  storeProfileSetting: InitialStoreProfileState;
};

const data = getInitialSettings();

export const initialSettingState: SettingState = {
  data: data || {},
  generalSetting: { loading: false },
  storeSetting: { loading: false },
  storeProfileSetting: { loading: false },
};

declare module './../../models' {
  interface StateAll {
    // match to a duck folder
    'common/initialSetting': SettingState;
  }
}
