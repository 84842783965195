import { stringify } from 'qs';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { API_ENDPOINTS } from 'src/config/api-endpoints';
import { axiosInstance } from '../../../../utils/axios/axios-instance';
import * as actions from './actions';
import { generateSingleKey } from 'src/utils/axios/axios-fecth';

// Handle request saga
function* getCodeList(action: ReturnType<typeof actions.getCodeList.request>): Generator {
  try {
    let key_axios = generateSingleKey(API_ENDPOINTS.CODE.List, action.payload);
    let responseData = null;

    //console.log('key_axios', key_axios);
    //console.log('action.payload', action.payload);
    //console.log('-----------------------------------');

    let cacheObject = window.localStorage.getItem(key_axios);
    if (cacheObject) {
      responseData = JSON.parse(cacheObject);
    } else {
      const response = yield call(axiosInstance.get, API_ENDPOINTS.CODE.List, {
        params: action.payload,
        paramsSerializer: (params) => stringify(params),
      });

      responseData = (response as any).data;
      if (responseData.success) {
        window.localStorage.setItem(key_axios, JSON.stringify(responseData));
      }
    }

    yield put(
      actions.getCodeList.success({
        responseData,
        codeName: action.payload.codeName,
        keyCodeName: action.payload.keyCodeName,
      })
    );
  } catch (err) {
    yield put(
      actions.getCodeList.failure({
        errors: err,
        codeName: action.payload.codeName,
        keyCodeName: action.payload.keyCodeName,
      })
    );
  }
}

// Main saga
export default function* codeSaga() {
  yield all([takeEvery(actions.getCodeList.request, getCodeList)]);
}
