import { all, call, put, takeEvery } from 'redux-saga/effects';
import { API_ENDPOINTS } from 'src/config/api-endpoints';
import { convertToFormData } from 'src/utils/converter';
import { axiosInstance } from '../../../../utils/axios/axios-instance';
import { generateSingleKey, removeCacheConfig } from '../../../../utils/axios/axios-fecth';

import * as actions from './actions';
import { getWorkstationId } from '../auth/storage';

// Handle request saga
function* getSettingList(action: ReturnType<typeof actions.getSettingList.request>): Generator {
  try {
    const { store_id, code } = action.payload;

    let key_axios = generateSingleKey(API_ENDPOINTS.STORE_SETTING.List, {
      code: code,
      store_id: store_id,
    });

    let responseData = null;
    let cacheObject = window.localStorage.getItem(key_axios);
    if (cacheObject) {
      responseData = JSON.parse(cacheObject);
    } else {
      const response = yield call(axiosInstance.get, `${API_ENDPOINTS.STORE_SETTING.List}`, {
        params: action.payload,
      });

      responseData = (response as any).data;
      if (responseData.success) {
        window.localStorage.setItem(key_axios, JSON.stringify(responseData));
      }
    }

    yield put(actions.getSettingList.success(responseData));

    if (store_id && code) {
      yield put(actions.clearGroupData({ storeId: store_id, code }));
    }

    if (store_id && responseData.success && responseData.data) {
      yield put(actions.setData({ storeId: store_id, data: responseData.data }));
    }
  } catch (err) {
    yield put(actions.getSettingList.failure(err));
  }
}

// Handle request connect payment setting saga
function* getConnectPaymentSetting(action: ReturnType<typeof actions.getConnectPaymentSetting.request>): Generator {
  try {
    const response = yield call(() =>
      axiosInstance.post(`${API_ENDPOINTS.STORE_SETTING.connectPayment}`, action.payload)
    );
    yield put(actions.getConnectPaymentSetting.success((response as any).data));
  } catch (err) {
    yield put(actions.getConnectPaymentSetting.failure(err));
  }
}

function* updateSettings(action: ReturnType<typeof actions.updateSettings.request>): Generator {
  try {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: (data) => convertToFormData(data),
    };
    const { store_id, code, settings } = action.payload;
    const url = API_ENDPOINTS.STORE_SETTING.Update.replace('{store_id}', store_id).replace('{code}', code);

    const response = yield call(() => axiosInstance.post(url, settings, config));

    yield put(actions.updateSettings.success((response as any).data));
    yield put(actions.hasUpdatedData());

    if (code === 'others') {
      yield removeCacheConfig(store_id, 'initial', '', '');
    }

    if (store_id && code) {
      //remove cache when user updated
      yield removeCacheConfig(store_id, code, API_ENDPOINTS.STORE_SETTING.List);
      const workstationId = (yield getWorkstationId()) as string;
      if (code === 'print') {
        yield removeCacheConfig(store_id, 'initial_store', '', workstationId);
      }
      if (code === 'print' || code === 'profile' || code === 'payment') {
        yield removeCacheConfig(store_id, 'initial_profile', '', workstationId);
      }
      yield put(actions.clearGroupData({ storeId: store_id, code }));
    }
  } catch (err) {
    yield put(actions.updateSettings.failure(err));
  }
}

function* updateManySettings(action: ReturnType<typeof actions.updateManySettings.request>): Generator {
  try {
    const response = yield call(() => axiosInstance.post(API_ENDPOINTS.STORE_SETTING.UpdateMany, action.payload));

    yield put(actions.updateManySettings.success((response as any).data));
  } catch (err) {
    yield put(actions.updateManySettings.failure(err));
  }
}

// Main saga
export default function* storeSettingSaga() {
  yield all([takeEvery(actions.getSettingList.request, getSettingList)]);
  yield all([takeEvery(actions.updateSettings.request, updateSettings)]);
  yield all([takeEvery(actions.updateManySettings.request, updateManySettings)]);
  yield all([takeEvery(actions.getConnectPaymentSetting.request, getConnectPaymentSetting)]);
}
