import {
  InitialGeneralSettings,
  InitialGeneralSettingsRequest,
  InitialGeneralSettingsResponse,
  InitialStoreSettings,
  InitialStoreSettingsRequest,
  InitialStoreSettingsResponse,
  InitialProfileSettingsResponse,
} from 'src/state/api-models/setting';
import { createAsyncAction, createAction } from 'typesafe-actions';
import * as types from './types';

// Create the set of async actions
export const getInitialGeneralSettings = createAsyncAction(
  types.FETCH_GENERAL_SETTING,
  types.FETCH_GENERAL_SETTING_SUCCESS,
  types.FETCH_GENERAL_SETTING_FAILED
)<InitialGeneralSettingsRequest, InitialGeneralSettingsResponse, any>();

export const getInitialStoreSettings = createAsyncAction(
  types.FETCH_STORE_SETTING,
  types.FETCH_STORE_SETTING_SUCCESS,
  types.FETCH_STORE_SETTING_FAILED
)<InitialStoreSettingsRequest, InitialStoreSettingsResponse, any>();

export const getInitialProfileSettings = createAsyncAction(
  types.FETCH_STORE_PROFILE_SETTING,
  types.FETCH_STORE_PROFILE_SETTING_SUCCESS,
  types.FETCH_STORE_PROFILE_SETTING_FAILED
)<InitialStoreSettingsRequest, InitialProfileSettingsResponse, any>();

export const setInitialGeneralSettingData = createAction(types.SET_GENERAL_DATA)<InitialGeneralSettings>();

export const setInitialStoreSettingData = createAction(types.SET_STORE_DATA)<{
  storeId: string;
  data: InitialStoreSettings;
}>();

export const clearData = createAction(types.CLEAR_DATA)();

export const clearSettingData = createAction(types.CLEAR_GROUP_DATA)<{ storeId: string }>();
